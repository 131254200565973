@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";

html {
  margin: 0 auto 0 auto;
  width: 100%;
  height: 100%;
}

.app-content {
  background-color: #f5f5f5;
  min-width: 250px;
  max-width: 600px;
  margin: 0 auto 0 auto !important;
  height: 100%;
  overflow: scroll !important;
}

.header-navbar.fixed-top {
  left : 0px !important;
}

#video-stream {
  // width: 100vw;
  // height: 100vh;
  width: 100%;
  height: 60vh;
  position: relative;
}

#take-picture {
  // width: 100vw;
  // height: 100vh;
  width: 100%;
  height: auto;
  position: relative;
}

#take-picture video {
  width: 100%;
  height: auto;
}

.react-html5-camera-photo img {
  width: 100%;
  height: auto;
}

video {
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
}

// .camera {
//   width: 100vw;
//   height: 100vh;
// }

.greenprogress {
  height: 40px;
  width: 220px;
  border-radius:12px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 5px;
  display: table;
}

.greenprogress2 {
  height: 30px;
  width: 100%;
  padding: 0px 5px;
  border-radius:6px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 5px;
  display: table;
}

.predlabel2 {
  // padding: 2% 0;
  height: 30px;
}

.box {
  position:relative;
}

.bar {
  background-color: #ffffff30;
  border-radius: 10px;
  transition: all .3s linear;
}

.blur {
  border-radius:12px;
  overflow: hidden;
  // position: fixed;
  position: absolute;
  bottom: 30px;
  left: 30px;
  height: 140px;
  width: 230px;
  backdrop-filter: blur(10px);
  background-color: #00000030;
}

.blur2 {
  border-radius:12px;
  overflow: hidden;
  // position: fixed;
  position: absolute;
  bottom: 70px;
  right: 10px;
  height: 300px;
  width: 160px;
  // backdrop-filter: blur(10px);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: white;
}

.predlabel{
  // padding: 2% 0;
  height: 40px;
}

.ripple-full {
  width:100%;
}

#container-circles {
  position: absolute;
  left: 50%;
  bottom: 90px;
}

#outer-circle {
  position: absolute;
  left: -37px;

  height: 75px;
  width: 75px;

  /*
    opacity of 0.4
  */
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;

  z-index: 1;
}

#inner-circle {
  position: absolute;
  left: 50%;
  top: 38px;

  height: 44px;
  width: 44px;

  background: white;
  border-radius: 50%;

  /*
   Offset the position correctly with
   minus half of the width and minus half of the height
  */
  margin: -22px 0px 0px -22px;

  /*
    On the top of outer-circle
  */
  z-index: 2;
}

#inner-circle.is-clicked {
  height: 38px;
  width: 38px;
  margin: -19px 0px 0px -19px;
}

.demo-image-preview {
  position: relative;
  text-align: center;
}

.demo-image-preview > img {
  width: 768px;
  height: auto;
  border-radius: 12px;
}

@media(max-width:768px){
  .demo-image-preview > img {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
}

/* fullscreen enable by props */
.demo-image-preview-fullscreen > img {
  width: 100vw;
  height: auto;
  border-radius: 12px;
}

.p5Canvas {
  z-index: 3;
  border-radius: 12px;
  height: 60vh;
}

 .swiper-wrapper {
  padding-top:2.2rem;
 }

 .icon-card-section {
   margin-left: 1.5rem;
 }

 .avatar-logo {
   width : 32px !important;
   height : 32px !important;
 }

 .avatar {
   overflow: hidden;
 }

 .avatar-card {
  width : 50px !important;
  height : 50px !important;
}

 .logo-circle {
  border: 3px #FFFFFF solid;
 }

 .navbar-shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05) !important;
}

.avatar-stats {
  border: 3px #FFFFFF solid;
}

.avatar-coverflow {
  border: 5px #FFFFFF solid !important;
  box-shadow: 0 4px 10px 0 rgba(0,0,0, 0.3) !important;
}

.overlay-black {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1.0) 100%);
}

.topAds {
  height:100px !important;
}

.adsense-mobile {
  height:100px !important;
}

.footer {
  min-width: 250px;
  max-width: 600px;
  margin: 0 auto 0 auto !important;
}

ins {
  margin : 0 auto;
}
